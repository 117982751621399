







































import { Component } from "vue-property-decorator"
import Dealer from "@/types/Dealers/Dealer"
import DealerApi from "@/api/Dealers/DealerApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
// @ts-ignore
import { SwiperOptions } from "swiper"
// @ts-ignore
import NextBtn from "@/assets/buttons/next.png"
// @ts-ignore
import PrevBtn from "@/assets/buttons/prev.png"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "ModernTradeCarousel",
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class ModernTradeCarousel extends mixins(BreakpointMixin) {
  private modernTrades: Dealer[] = []

  private nextBtn = NextBtn

  private prevBtn = PrevBtn

  // eslint-disable-next-line class-methods-use-this
  private swiperOptions: SwiperOptions = {
    autoplay: true,
    slidesPerView: 1,
    slidesPerGroup: 1,
    centeredSlides: true,
    spaceBetween: 10,
    preloadImages: true,
    updateOnImagesReady: true,
    updateOnWindowResize: true,
    freeMode: true,
    navigation: {
      nextEl: "#leadNextBtn",
      prevEl: "#leadPrevBtn"
    },
    height: 100,
    breakpoints: {
      960: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        centeredSlides: false,
        spaceBetween: 24
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private goToUrl(idx: number) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const elm = document.getElementById(`modernTradeImageUrl${idx}`)!
    elm.click()
  }

  created() {
    DealerApi.listModernTrades().then(({ data }) => {
      this.modernTrades = _.map(data, (d) => deserialize<Dealer>(d, Dealer))
    })
  }
}
