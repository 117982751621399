





























































































































































































































import { Component, Watch } from "vue-property-decorator"
import ViewWrapper from "@/views/ViewWrapper.vue"
import Dealer from "@/types/Dealers/Dealer"
import DealerApi from "@/api/Dealers/DealerApi"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import { deserialize } from "typescript-json-serializer"
import MapDialog from "@/components/Global/MapDialog.vue"
import ModernTradeCarousel from "@/components/Dealers/ModernTradeCarousel.vue"
import LeadingTradeCarousel from "@/components/Dealers/LeadingTradeCarousel.vue"
import PaginateDealer from "@/types/Dealers/PaginateDealer"
import { mixins } from "vue-class-component"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import ModernTradeMobile from "@/components/Dealers/ModernTradeMobile.vue"
import LeadingTradeMobile from "@/components/Dealers/LeadingTradeMobile.vue"
import DealerCountryData from "@/types/Dealers/DealerCountryData"
import DealerProvinceData from "@/types/Dealers/DealerProvinceData"
import DealerCountry from "@/types/Dealers/DealerCountry"
import DealerProvince from "@/types/Dealers/DealerProvince"

@Component({
  name: "DealerPage",
  components: {
    LeadingTradeMobile,
    ModernTradeMobile,
    LeadingTradeCarousel,
    ModernTradeCarousel,
    MapDialog,
    ViewWrapper,
    Swiper,
    SwiperSlide
  }
})
export default class DealerPage extends mixins(InputMixin, BreakpointMixin) {
  private mapDialog = false

  private mapContent = ""

  private page = 1

  private pageCount = 1

  private dealers: Dealer[] = []

  private countries: DealerCountryData[] = []

  private provinces: DealerProvinceData[] = []

  private country = new DealerCountryData()

  private province = new DealerProvinceData()

  private viewMap(item: Dealer) {
    this.mapContent = item.iFrame
    this.mapDialog = true
  }

  private get alphabet() {
    return this.$t("dealer.alphabet")
  }

  private dealerTemplate = [
    {
      label: "ชื่อร้าน",
      value: "shopName"
    },
    {
      label: "โทรศัพท์",
      value: "tel"
    },
    {
      label: "ที่อยู่",
      value: "address"
    }
  ]

  listDealers() {
    if (this.country && this.province) {
      DealerApi.listDealers(this.page, this.country.id, this.province.id).then(({ data }) => {
        const paginateDealer = deserialize<PaginateDealer>(data, PaginateDealer)
        this.dealers = paginateDealer.dealers
        this.pageCount = paginateDealer.pageCount
      })
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  @Watch("province")
  onProvinceChange() {
    this.listDealers()
  }

  @Watch("country")
  onCountryChange() {
    DealerApi.listProvince(this.country.id).then(({ data }) => {
      this.provinces = deserialize<DealerProvince>(data, DealerProvince).provinces
      ;[this.province] = this.provinces
    })
  }

  @Watch("page")
  onPageChange() {
    this.listDealers()
  }

  mounted() {
    this.fetchData()
  }

  private fetchData() {
    DealerApi.listCountries()
      .then(({ data }) => {
        this.countries = deserialize<DealerCountry>(data, DealerCountry).countries
        ;[this.country] = this.countries
      })
      .then(() => DealerApi.listProvince(this.country.id))
      .then(({ data }) => {
        this.provinces = deserialize<DealerProvince>(data, DealerProvince).provinces
        ;[this.province] = this.provinces
      })
      .then(this.listDealers)
  }
}
