























import { Component, Vue } from "vue-property-decorator"
import Dealer from "@/types/Dealers/Dealer"
import DealerApi from "@/api/Dealers/DealerApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"

@Component({
  name: "LeadingTradeMobile"
})
export default class LeadingTradeMobile extends Vue {
  private leadingTrades: Dealer[] = []

  // eslint-disable-next-line class-methods-use-this
  private goToUrl(idx: number) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const elm = document.getElementById(`leadImageUrl${idx}`)!
    elm.click()
  }

  created() {
    DealerApi.listLeadingTrades().then(({ data }) => {
      this.leadingTrades = _.map(data, (d) => deserialize<Dealer>(d, Dealer))
    })
  }
}
